<template>
  <div class="step-end-container">
    <intro v-if="v.intro" @next="next">이제 모두 완료되었습니다.<br>내 서비스 목록에서 확인할 수 있어요 :)</intro>
    <sweet-modal ref="modal" overlay-theme="dark" :blocking="true" @close="onClose">
      <div class="flex-align">
        <div class="circle">
          <img src="/static/img/ic_file_minus.svg">
        </div>
        <div>
          <div class="main h5">잠깐만요!</div>
          <div class="sub2 body4">더 나은 서비스 제공을 위해 런치팩을 알게 된 경로를 알려주세요.</div>
        </div>
      </div>
      <div class="content">
        <div v-for="(form,idx) in formData" :key="'form-'+idx">
          <div class="subtitle5 main" style="margin:32px 0 16px 0">{{ form.label }}</div>
          <template v-if="form.type === 'text'">
            <input type="text" v-model="form.value" @keyup="e => form.value = e.target.value"
                   :placeholder="form.placeholder">
          </template>
          <checkbox-group v-else-if="form.type === 'checkboxGroup'" :value.sync="form.value"
                          :option="form.option"></checkbox-group>
        </div>
        <button class="button is-primary" style="margin-top:32px;width:100%" @click="accept">완료</button>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import Intro from "./Intro";
  import CheckboxGroup from "../../module/CheckboxGroup";
  import {SweetModal} from 'sweet-modal-vue';
  import UserAPIMixin from "../../../mixins/UserAPIMixin";

  export default {
    name: "StepEnd",
    components: {
      SweetModal,
      CheckboxGroup,
      Intro,
    },
    props: {
      service: {
        type: Object
      },
      temporaryServiceId: {
        type: Number
      }
    },
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.request.user.get('launchpack/survey/form/1').then(res=>{
        this.formData = res.data.form;
      });
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.modal.open();
      });
    },
    data() {
      return {
        v: {
          intro: false
        },
        formData: [
          {
            type: 'checkboxGroup',
            label: '1. 런치팩을 알게된 경로를 선택해주세요.',
            option: [
              {
                label: 'SNS',
                value: 'SNS'
              },
              {
                label: '네이버 지식인',
                value: '네이버 지식인'
              },
              {
                label: '블로그',
                value: '블로그'
              },
              {
                label: '인터넷 검색',
                value: '인터넷 검색'
              },
              {
                label: '지인 소개',
                value: '지인 소개'
              }
            ],
            value: []
          },
          {
            type: 'text',
            label: '2. 인터넷 검색의 경우 검색 키워드를 입력해주세요.',
            value: '',
            placeholder: '검색 키워드를 입력해주세요'
          },
          {
            type: 'checkboxGroup',
            label: '3. 검색은 어디에서 하셨나요?',
            value: [],
            option: [
              {
                label: '네이버',
                value: '네이버'
              },
              {
                label: '다음',
                value: '다음'
              },
              {
                label: '네이트',
                value: '네이트'
              },
              {
                label: '구글',
                value: '구글'
              }
            ]
          }
        ]
      }
    },
    methods: {
      next() {
        this.v.intro = false;
        this.$refs.modal.open();
      },
      accept() {
        let params = this.formData.map(item=>{
          return {
            question: item.label,
            answer: item.value
          }
        });
        this.request.user.post('launchpack/survey', {form: params}).then(()=>{
          this.toast('등록되었습니다.');
          this.setGa('퀵빌더', '응답', '퀵빌더 설문완료');
          setTimeout(()=>{
            //this.$router.replace('mypage/temporary_storage');
            this.order();
          }, 700);
        }).catch(()=>{
          this.toast('서버에 오류가 발생하였습니다');
        });
      },
      onClose() {
        //this.$router.replace('mypage/temporary_storage');
        this.order();
      },
      order() {
        let price = 0;
        if(this.service.conversions) {
          let arr = this.service.conversions;
          if(this.service.plugins) {
            arr = arr.concat(this.service.plugins);
          } else {
            this.service.plugins = [];
          }
          if(arr.length>0) price = arr.map(item => { return item.add_option ? item.option.price.price : item.price.price }).reduce((acc,cur) => { return acc + cur } );
        }
        this.$store.commit('setBasket', {
          type: 'quickbuilder',
          name: this.user.name,
          phone: this.user.phone,
          email: this.user.email,
          title: this.service.service_name,
          price: price + (price/10),
          option_type: 1,
          service: { key: this.service.key, service: this.service, id: this.temporaryServiceId}
        });
        this.$router.push('/order');
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .circle
    background-color $primary-light2
    border-radius 100%
    width 88px
    height 88px
    display flex
    align-items center
    justify-content center
    margin-right 16px
  .content
    border-top 1px solid $gray1
    margin-top 32px
    overflow-x hidden
    overflow-y auto
    max-height calc(80vh - 190px)
</style>
<style lang="stylus">
  .step-end-container
    .sweet-modal.is-visible
      width 760px
      max-width 760px
      border-radius 12px
      max-height 80vh
      overflow hidden

    .sweet-modal.is-alert .sweet-content
      padding 40px 50px
      text-align left

</style>
